import React from "react";
import moment from "moment";
import {Card} from "react-bootstrap";
import {goPostUrl, strings} from "utils";
import stripHtml from "string-strip-html";

const CardHome = ({item:{id,post}}) => {
    return <Card style={{marginBottom:'15px', cursor:'pointer'}} key={id} onClick={()=>goPostUrl(id, post.title)}>
        <Card.Img variant="top" src={post.image} />
        <Card.Body>
            <Card.Title>{post.title}</Card.Title>
            <div className='card-content'>{stripHtml(post.content).result.substr(0,100)}</div>
            <span className='card-date'>{moment.unix(post.timestamp.seconds).format(strings.DateTimeFormat.DDMMYYYY_HHmm)}</span>
        </Card.Body>
    </Card>;
};

export { CardHome }