import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';
import { history,strings } from 'utils';
import 'styles/index.scss';
import {
    WebRouter,
    AuthRouter,
    AdminRouter
} from "context";
import {
    Login
} from 'pages/Auth';
import {
    Home, Hakkimda, Post
} from 'pages/WebPages';
import {
    Dashboard, EditPost, NewPost, Posts
} from "pages/Administrator";


const App = () => {
    let adminPrefix = strings.PATH.ADMIN;
    let postPrefix = strings.PATH.Post;

    return <Router history={history}>
        <Switch>
            <WebRouter exact path={'/'} component={Home}/>
            <WebRouter exact path={'/hakkimda'} component={Hakkimda}/>
            <WebRouter exact path={ postPrefix + '/:postId/:postName'} component={Post}/>
            <AuthRouter exact path={ adminPrefix + '/'} component={Login}/>
            <AdminRouter exact path={ adminPrefix + '/Dashboard'} component={Dashboard}/>
            <AdminRouter exact path={ adminPrefix + '/NewPost'} component={NewPost}/>
            <AdminRouter exact path={ adminPrefix + '/Posts'} component={Posts}/>
            <AdminRouter exact path={ adminPrefix + '/EditPost/:postId'} component={EditPost}/>
        </Switch>
    </Router>;
};

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
