import React from 'react';
import {
    Container,
    Navbar
} from "react-bootstrap";
import {Route} from "react-router-dom";
import {Icon} from "components";
import {Img} from "react-image";
import 'styles/AuthRouter.scss';

const AuthRouter = ({component: Component,  ...rest}) => {


    return <Route {...rest} render={ matchProps => (
        <>
            <Navbar bg="light" variant="light" className='shadow-sm'>
                <Container className='justify-content-center'>
                    <Img
                        src={ process.env.PUBLIC_URL + '/image/logo_header.png' }
                        loader={<Icon item='spinner fa-spin' />}
                        unloader={<Icon item='exclamation-triangle' />}
                        alt={'M. Fatih ŞANAL'}
                        title={'M. Fatih ŞANAL'}
                        height={'25'}
                    />
                    &#123; /AdminPanel &#125;
                </Container>
            </Navbar>
            <br/>
            <Component {...matchProps} />
        </>
    )} />;
};

export { AuthRouter };