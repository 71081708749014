import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Container,
    Row
} from "react-bootstrap";
import {db,strings,goPostsNextPage} from 'utils';
import {CardHome} from "components";

const Home = () => {
    const [ postList, setPostList ] = useState([]);
    const [ totalPost, setTotalPost ] = useState(0);
    const [ pagePostLimit] = useState(21);


    useEffect(()=>{
        db.collection(strings.DBTables.POSTS).orderBy("timestamp", "desc").limit(pagePostLimit).get()
            .then(snapshot=>{
                setPostList(snapshot.docs.map(doc=>({
                    id: doc.id,
                    post: doc.data()
                })))
                setTotalPost( snapshot.docs.length )
            });
        // eslint-disable-next-line
    },[]);

    const getFilteredList = (list, mod, col) => {
        return list.filter((a,i) => i % mod === col);
    };

    return <Container>
        <Row>
            <Col md={4}>
                { getFilteredList(postList,3,0).map(item=><CardHome item={item} />) }
            </Col>
            <Col md={4}>
                { getFilteredList(postList,3,1).map(item=><CardHome item={item} />) }
            </Col>
            <Col md={4}>
                { getFilteredList(postList,3,2).map(item=><CardHome item={item} />) }
            </Col>
        </Row>
        {
            totalPost >= pagePostLimit && <Row>
                <Col md={12}>
                    <Button variant="secondary" type="button" block onClick={()=>goPostsNextPage(2)}>
                        Diğer Yazılar
                    </Button>
                </Col>
            </Row>
        }

    </Container>;
};

export { Home };