import React, {useEffect, useState} from "react";
import {Card,  Table} from "react-bootstrap";
import {db, strings, genPostUrl, goAdminUrl, storage} from "utils";
import {Icon} from "components";
import moment from "moment";

const Posts = () => {
    const [ postList, setPostList ] = useState([]);

    const removePost = item => {
        if (window.confirm(item.post.title + '\nbaşlıklı yazıyı silmek istediğinize emin misiniz?')) {
            storage.ref()
                .child(strings.DBTables.POSTS + '/' + item.post.imagePath )
                .listAll()
                .then(images => {
                    images.items.forEach(image =>{
                        storage.ref()
                            .child(image.fullPath)
                            .delete()
                            .then();
                    });
                    db.collection(strings.DBTables.POSTS)
                        .doc(item.id)
                        .delete()
                        .then(()=>{
                            window.location.reload();
                        })
                })
        }
    }

    useEffect(()=>{
        db.collection(strings.DBTables.POSTS).orderBy("timestamp", "desc").get()
            .then(snapshot=>{
                setPostList(snapshot.docs.map(doc=>({
                    id: doc.id,
                    post: doc.data()
                })))
            });
    },[]);

    return <Card body>
        <Table responsive="lg">
            <thead>
            <tr>
                <th>#</th>
                <th>Başlık</th>
                <th>Tarih</th>
                <th>İşlem</th>
            </tr>
            </thead>
            <tbody>
            {
                postList.map((item,index)=><tr key={item.id}>
                    <td>{index+1}</td>
                    <td>{item.post.title}</td>
                    <td>{moment.unix(item.post.timestamp.seconds).format(strings.DateTimeFormat.DDMMYYYY_HHmmss)}</td>
                    <td>
                        <Icon item='search pointer postIcon' onClick={ ()=> window.open(genPostUrl(item.id, item.post.title))} />
                        &nbsp;
                        <Icon item='edit pointer postIcon' onClick={ ()=> goAdminUrl(`EditPost/${item.id}`)} />
                        &nbsp;
                        <Icon item='remove pointer postIcon' onClick={ ()=> removePost(item)} />
                    </td>
                </tr>)
            }
            </tbody>
        </Table>
    </Card>;
};

export {Posts}