import React, {useEffect, useState} from 'react';
import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import {db,strings} from "utils";
import {CardPost} from "components/CardPost";
import {Sidebar} from "pages/WebPages";

const Post = props => {
    const [ post, setPost ] = useState(null);
    const [ postId ] = useState(props.match.params.postId || '123');

    useEffect(()=>{
        db.collection(strings.DBTables.POSTS)
            .doc(postId)
            .get()
            .then(doc=>{
                setPost({
                    id: doc.id,
                    post: doc.data()
                })
            });
        // eslint-disable-next-line
    },[]);

    return <Container>
        <Row>
            <Col md={8}>
                <CardPost item={post} />
            </Col>
            <Col md={4}>
                <Sidebar />
            </Col>
        </Row>
    </Container>;
};

export { Post };