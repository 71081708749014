import React from 'react';
import {
    Container,
    Nav,
    Navbar
} from "react-bootstrap";
import {Route} from "react-router-dom";
import {Icon} from "components";
import {Img} from "react-image";
import 'styles/WebRouter.scss';

const WebRouter = ({component: Component,  ...rest}) => {


    return <Route {...rest} render={ matchProps => (
        <>
            <Navbar bg="light" variant="light" className='shadow-sm'>
                <Container>
                    <Navbar.Brand href="/" className="mr-md-auto">
                        <Img
                            src={ process.env.PUBLIC_URL + '/image/logo_header.png' }
                            loader={<Icon item='spinner fa-spin' />}
                            unloader={<Icon item='exclamation-triangle' />}
                            alt={'M. Fatih ŞANAL'}
                            title={'M. Fatih ŞANAL'}
                            height={'25'}
                        />
                    </Navbar.Brand>
                    <Nav className="">
                        <Nav.Link href="/">Anasayfa</Nav.Link>
                        <Nav.Link href="/hakkimda">Hakkımda</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <br/>
            <Component {...matchProps} />
        </>
    )} />;
};

export { WebRouter };