import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from "utils";

const Error = ({ error, name }) => {
    return <i className='colorRed'>{
        error[name] && formValues.errors[name]
    }</i>;
};
Error.propTypes ={
    error: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
};

export { Error }