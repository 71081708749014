import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {Input} from "components";
import {auth, APP_KEY, goAdminUrl, storageSet, storageGet} from "utils";

const Login = () => {
    const { register, errors, handleSubmit } = useForm();
    const [errorMessage,setErrorMessage] = useState();

    const submitForm = e => {
        auth.signInWithEmailAndPassword(e.userName,e.password)
            .then(user=>{
                console.log(user)
                storageSet(APP_KEY,user)
                goAdminUrl('Dashboard')
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    };

    useEffect(()=>{
        if(storageGet(APP_KEY))
            goAdminUrl('Dashboard')
    },[])

    return <Container>
        <Row>
            <Col md={{ span:6, offset:3 }}>
                <br />
                <Card body className='padding35'>
                    <h1 style={{textAlign:'center', marginBottom:'35px'}}>
                        <img src={process.env.PUBLIC_URL + '/image/logo_header.png'} alt="M. Fatih ŞANAL"/>
                    </h1>
                    <i className='colorRed'>{errorMessage}</i>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Input
                            name='userName'
                            error={ errors }
                            rules={ register({ required: true }) }
                        />
                        <Input
                            name='password'
                            type='password'
                            error={ errors }
                            rules={ register({ required: true }) }
                        />
                        <Button variant="primary" type="submit" block>
                            Giriş Yap
                        </Button>
                    </form>
                </Card>
            </Col>
        </Row>
    </Container>;
};

export {Login}