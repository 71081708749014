import React, {useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {db, storage, strings, getFileExt, goAdminUrl, editorConfig} from "utils";
import {Input} from "components";
import firebase from "firebase";
import {useForm} from "react-hook-form";
import JoditEditor from "jodit-react";

const fileName = Date.now()+'_'+Math.floor(100000 + Math.random() * 900000);
let editorContent = '';

const NewPost = () => {
    const { register, errors, handleSubmit } = useForm();
    const [ progress, setProgress ] = useState(-1);

    const submitForm = e => {
        setProgress(0);
        let file = e.postFile[0];
        let postFileName = 'coverImage.' + getFileExt(file.name);
        const uploadTask = storage
            .ref()
            .child(`${strings.DBTables.POSTS}/${fileName}_${postFileName}`)
            .put(file);

        uploadTask.on(
            "state_changed",
            null,
            (error) => {
                console.log(error)
                window.alert('Bir Hata Oluştu. Kontrol ediniz')
                setProgress(-1)
            },
            () => {
                storage
                    .ref()
                    .child(`${strings.DBTables.POSTS}/${fileName}_${postFileName}`)
                    .getDownloadURL()
                    .then(url => {
                        db.collection(strings.DBTables.POSTS).add({
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            title: e.postTitle,
                            content: editorContent,
                            image: url,
                            imagePath: fileName
                        })
                            .then(function(docRef) {
                                console.log("Document written with ID: ", docRef.id);
                                setTimeout(()=> setProgress(25),250);
                                setTimeout(()=> setProgress(50),500);
                                setTimeout(()=> setProgress(75),750);
                                setTimeout(()=>{
                                    setProgress(100);
                                    setTimeout(()=>goAdminUrl('Posts'),1000)
                                },1000)
                            });
                    })
            }
        )
    };

    return <>
        <Card body>
            {
                progress < 0 && <form onSubmit={handleSubmit(submitForm)}>
                    <Input
                        name='postTitle'
                        error={ errors }
                        rules={ register({ required: true }) }
                    />
                    <Input
                        name='postFile'
                        type='file'
                        accept="image/x-png,image/gif,image/jpeg"
                        error={ errors }
                        rules={ register({ required: true }) }
                        className='form-file-upload'
                    />
                    <Form.Group>
                        <JoditEditor
                            value={editorContent}
                            onChange={e=> editorContent = e}
                            config={{
                                "language": "tr",
                                "enter": "DIV",
                                "buttons": editorConfig(),
                                "buttonsXS": editorConfig(),
                                "buttonsMD": editorConfig(),
                                "buttonsSM": editorConfig()
                            }}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" block>
                        Kaydet
                    </Button>
                </form>
            }
            {
                progress >= 0 && <div>
                    <progress value={progress} max={100} style={{width:'100%'}} />
                    Kaydediliyor...
                </div>
            }
        </Card>
    </>;
};

export {NewPost}