import {APP_KEY, history, storageRemove, strings} from 'utils';
import {Img} from 'react-image'
import React from "react";
import {Icon} from "components";

export const historyPush = (path, params=null) => {
    history.push(path,params);
};
export const objectToArray = obj => {
    let tmp = [];
    for (let k in obj) {
        if (obj.hasOwnProperty(k)) {
            tmp.push({
                key:k,
                val:obj[k]
            });
        }
    }
    return tmp;
};
export const trimSpaces = text => {
    return text.replace(/\s+/g, '');
}
export const onlyNumber = str => {
    return str.replace(/[^0-9]+/g, '');
};
export const checkSelectedBox = selected => {
    return selected !== 0;
};
export const sportNameToSportSlug = str => {
    return trimSpaces(str);
};
export const sportSlugToSportName = slug => {
    return slug.split(/(?=[A-Z])/).join(" ");
};
export const getFileExt = file => {
    let fileArray=file.split('.');
    return fileArray[fileArray.length-1];
};
export const currentPath = () => {
    let path = window.location.pathname.split('/');
    return `${ path[1] }/${ path[2] }`;
};
export const currentUrl = () => {
    return `/${ currentPath() }`;
};
export const getUrlId = () => {
    let path = window.location.pathname.split('/');
    return path[path.length-1];
};
export const getPathType = () => {
    let path = window.location.pathname.split('/');
    return path[path.length-2];
};
export const convertFileSize = fileSize => {
    return ( fileSize / 1000000 ).toFixed(2);
};
export const logoutUser = () => {
    storageRemove(APP_KEY)
    window.location.href = strings.PATH.ADMIN + '/';
};
export const getNewDate = date => {
    let d = date.split("-")
    return new Date(d[2],d[1]-1,d[0])
};
export const getDropdownItem = sportType => {
    return <>
        <Img
            src={ process.env.PUBLIC_URL + '/image/' + sportType +'.png' }
            loader={<Icon item='spinner fa-spin' />}
            unloader={<Icon item='exclamation-triangle' />}
            alt={sportType}
            title={sportType}
            width={20}
        /> &nbsp;
        { sportType }
    </>;
}
export const getSportIcon = ( icon, isLive=true) => {
    let image = isLive ? icon +'_live' : icon;
    return <Img
        src={ process.env.PUBLIC_URL + '/image/' + image +'.png' }
        loader={<Icon item='spinner fa-spin' />}
        unloader={<Icon item='exclamation-triangle' />}
        alt={isLive ? icon +' Canlı' : icon}
        title={isLive ? icon +' Canlı' : icon}
    />;
}
export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}
export const getSRIcon = (icon, size = 2 ) => {
    let picSize = size === 1 ? 'small' : size === 2 ? 'medium' : 'big';
    if (icon)
        return <Img
            src={'https://ls.sportradar.com/ls/crest/' + picSize + '/' + icon + '.png'}
            loader={<Icon item='spinner fa-spin'/>}
            unloader={<Icon item='globe'/>}
        />;
    else
        return <Img
            src={<Icon item='globe'/>}
            loader={<Icon item='spinner fa-spin'/>}
            unloader={<Icon item='globe'/>}
        />;
}
export const MMCBlink = time => {
    return (
        time === 'Maç Sonucu'
        || time === 'Başlamadı'
        || time === 'Ertelendi'
        || time === 'İptal'
        || time === 'Penaltılar'
        || time === 'Çekildi'
    )
        ? ''
        : ' blink';
}
export const slugify = text => {
    const trMap = {
        'çÇ':'c',
        'ğĞ':'g',
        'şŞ':'s',
        'üÜ':'u',
        'ıİ':'i',
        'öÖ':'o'
    };
    for(let key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
    }
    return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
        .replace(/\s/gi, "-") // convert spaces to dashes
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase();
}
export const goPostUrl = (id, title) => {
    window.location.href = strings.PATH.Post + '/' + id + '/' + slugify(title) + '.html';
}
export const genPostUrl = (id, title) => {
    return  strings.PATH.Post + '/' + id + '/' + slugify(title) + '.html';
}
export const goPostsNextPage = page => {
    window.location.href = strings.PATH.Posts + '/' + page;
}
export const goAdminUrl = path => {
    window.location.href = strings.PATH.ADMIN + '/' + path;
}
export const genAdminUrl = path => {
    return strings.PATH.ADMIN + '/' + path;
}
export const StringToMD = (data) =>{
    let reVal = data||'';
    reVal = reVal.replace(/###/g, '\n ###');
    reVal = reVal.replace(/\\/g, '\n');
    return reVal;
};

export const byteLength =str=> {
    // returns the byte length of an utf8 string
    let s = str.length;
    for (let i=str.length-1; i>=0; i--) {
        let code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s+=2;
        if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
    }
    return s;
}
export const listFiller = (list, size) => {
    if(list.length >= size) {
        console.log(list)
        return list;
    }else {
        setTimeout(()=>{
            return listFiller(list,size)
        },100);
    }
}