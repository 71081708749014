import React from "react";
import moment from "moment";
import {Card} from "react-bootstrap";
import {StringToMD,strings} from "utils";
import {Comment} from "components";
import MDEditor from '@uiw/react-md-editor';


const CardPost = ({ item }) => {
    return item && <>
        <Card style={{ marginBottom:'15px' }} key={ item.id }>
            <Card.Img variant="top" src={ item.post.image } />
            <Card.Body>
                <Card.Title>{ item.post.title }</Card.Title>
                <span className='card-date'>
                    { moment.unix(item.post.timestamp.seconds).format(strings.DateTimeFormat.DDMMYYYY_HHmm) }
                </span>
                <br/>
                <div className='card-content'>
                    <MDEditor.Markdown source={StringToMD(item.post.content)} />
                </div>
            </Card.Body>
        </Card>
        <Comment title={item.post.title} identifier={item.id} url={window.location.href} />
    </>;
};

export { CardPost }