import React from 'react';
import Disqus from "disqus-react";
import {Card} from "react-bootstrap";

const Comment = ({url,identifier,title}) => {
    const shortname = 'blog-mfsanal';
    const config = { url, identifier, title };
    return (
        <Card style={{padding:'10px'}}>
            <Disqus.DiscussionEmbed shortname={shortname} config={config} />
        </Card>
    );
}

export {Comment};