import React from 'react';
import {ListGroup, Card} from "react-bootstrap";
import {Icon} from "components";
import {goAdminUrl} from "utils";

const Sidebar = () => {

    return <Card body>
        <ListGroup variant="flush">
            <ListGroup.Item className='pointer' onClick={()=>goAdminUrl('NewPost')}>
                <Icon item='plus' /> Yeni Yazı
            </ListGroup.Item>
            <ListGroup.Item className='pointer' onClick={()=>goAdminUrl('Posts')}>
                <Icon item='list' /> Yazılarım
            </ListGroup.Item>
        </ListGroup>
    </Card>;
};

export { Sidebar };