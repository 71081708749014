import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";

const Hakkimda = () => {
    return <Container>
        <Row>
            <Col md={12}>
                <Card body style={{textAlign:'center'}}>
                    <h1>Hakkımda</h1>
                    <p>
                        GITHUB : <a href="https://github.com/mfsanal">https://github.com/mfsanal</a>
                    </p>
                </Card>
            </Col>
        </Row>
    </Container>;
};

export {Hakkimda}