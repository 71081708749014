import React, {useEffect, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {db, strings, goAdminUrl, editorConfig} from "utils";
import {Input} from "components";
import {useForm} from "react-hook-form";
import JoditEditor from "jodit-react";

let editorContent = '';

const EditPost = props => {
    const { register, errors, handleSubmit } = useForm();
    const [ progress, setProgress ] = useState(-1);
    const [ post, setPost ] = useState(null);
    const [ postId ] = useState(props.match.params.postId || '123');

    useEffect(()=>{
        db.collection(strings.DBTables.POSTS)
            .doc(postId)
            .get()
            .then(doc=>{
                setPost({
                    id: doc.id,
                    post: doc.data()
                })
            });
        // eslint-disable-next-line
    },[]);

    const submitForm = e => {
        console.log(editorContent)
        setProgress(0)
        db.collection(strings.DBTables.POSTS).doc(postId).update({
            content: editorContent
        })
            .then(function() {
                console.log("Document successfully updated!");
                setTimeout(()=> setProgress(25),250);
                setTimeout(()=> setProgress(50),500);
                setTimeout(()=> setProgress(75),750);
                setTimeout(()=>{
                    setProgress(100);
                    setTimeout(()=>goAdminUrl('Posts'),1000)
                },1000)
            });
    };

    return post && <>
        <Card body>
            {
                progress < 0 && <form onSubmit={handleSubmit(submitForm)}>
                    <Input
                        name='postTitle'
                        value={post.post.title}
                        error={ errors }
                        rules={ register({ required: true }) }
                        disabled
                    />
                    <Form.Group>
                        <img src={post.post.image} alt={post.post.title} className='embed-responsive' />
                    </Form.Group>
                    <Form.Group>
                        <JoditEditor
                            value={post.post.content}
                            onChange={e=> editorContent = e}
                            config={{
                                "language": "tr",
                                "enter": "DIV",
                                "buttons": editorConfig(),
                                "buttonsXS": editorConfig(),
                                "buttonsMD": editorConfig(),
                                "buttonsSM": editorConfig()
                            }}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" block>
                        Güncelle
                    </Button>
                </form>
            }
            {
                progress >= 0 && <div>
                    <progress value={progress} max={100} style={{width:'100%'}} />
                    Kaydediliyor...
                </div>
            }
        </Card>
    </>;
};

export {EditPost}