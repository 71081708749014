import React, {useEffect, useState} from 'react';
import {db, strings} from 'utils';
import {CardHome} from "components";

const Sidebar = () => {
    const [ postList, setPostList ] = useState([]);

    useEffect(()=>{
        db.collection(strings.DBTables.POSTS).orderBy("timestamp", "desc").limit(3).get()
            .then(snapshot=>{
                setPostList(snapshot.docs.map(doc=>({
                    id: doc.id,
                    post: doc.data()
                })))
            });
    },[]);



    return <>
        <h3 align='center'><i>Son Yazılar</i></h3>
        { postList.map(item=><CardHome item={item} />) }
    </>;
};

export { Sidebar };