import {db, getFileExt, storage, strings} from "utils";
import firebase from "firebase";

export const editorConfig = () => {
    const fsImage = {
        iconURL: `${process.env.PUBLIC_URL}/image/img.png`,
        tooltip: 'Resim Yükle',
        exec: function (editor) {
            let input = document.createElement('input');
            input.type="file";
            input.accept="image/x-png,image/gif,image/jpeg"
            input.onchange = e =>{
                let file = e.path[0].files[0];
                let rdmNumber = Math.floor(100000 + Math.random() * 900000);
                let postName = Date.now() + '_' + rdmNumber + '_' + file.size + '.' + getFileExt(file.name);
                const uploadTask = storage
                    .ref()
                    .child(`${strings.DBTables.IMAGES}/${postName}`)
                    .put(file);
                uploadTask.on(
                    "state_changed",
                    null,null,
                    () => {
                        storage
                            .ref()
                            .child(`${strings.DBTables.IMAGES}/${postName}`)
                            .getDownloadURL()
                            .then(url => {
                                db.collection(strings.DBTables.IMAGES).add({
                                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                                    name: postName,
                                    url: url
                                }).then(function(docRef) {
                                    console.log("Document written with ID: ", docRef.id);
                                    let ImageElement = document.createElement('img');
                                    ImageElement.src=url;
                                    ImageElement.alt = postName
                                    editor.selection.insertNode(ImageElement);
                                    editor.setEditorValue();
                                });
                            });
                    }
                )
            };
            input.click();
        }
    };
    const imageBrowser = {
        iconURL: `${process.env.PUBLIC_URL}/image/img-browser.png`,
        tooltip: 'image Browser',
        exec: function (editor) {
            let win_width = 1024;
            let win_height = 768;
            let win_left = (window.screen.width/2) - (win_width/2);
            let win_top = (window.screen.height/2) - (win_height/2);
            let win_config = "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width="+win_width+",height="+win_height+",top="+win_top+",left="+win_left;

            let win = window.open("", 'image Browser', win_config);
            win.document.body.innerHTML = 'Loading....';

            win.imageUrl = '';
            win.SelectedItem = (t,url,name) =>{
                win.imageUrl=url;
                let x = win.document.getElementsByClassName("list-image");
                for (let i = 0; i < x.length; i++) {
                    x[i].style.borderColor = "#474747";
                    x[i].style.backgroundColor = "white";
                }
                t.style.borderColor = "red";
                t.style.backgroundColor = "red";
                win.document.getElementById("pr").src = url;
                win.document.getElementById("prT").innerHTML=name;
            }
            win.acceptItem = () => {
                let ImageElement = document.createElement('img');
                ImageElement.src = win.imageUrl;
                ImageElement.alt = 'postName'
                editor.selection.insertNode(ImageElement);
                editor.setEditorValue();
                win.close();
            }

            let html = '<style>' +
                'body { background: #7d8b99 }' +
                '.ib-content { display: flex; height: 410px; background: #b8bbbc; padding: 15px; border: 1px solid #ffe8a1; border-radius: 5px; margin-bottom: 10px}' +
                '.ib-pr { display: flex;  height: 200px; background: #b8bbbc; padding: 5px; border: 1px solid #ffe8a1; border-radius: 5px}' +
                '.list-image{ width: 100px; height: 100px; padding: 5px; margin: 5px; border: 1px solid #474747; border-radius: 4px; cursor: pointer;}' +
                '.list-image > img { width: 100px; height: 100px;}' +
                '.ib-pr > .img { width: 80%; padding: 5px; text-align: center}' +
                '.ib-pr > .img > img {  max-height: 190px}' +
                '.ib-pr > .btn {width: 20%; margin-top: 75px;}' +
                '.ib-pr > .img > span { position: absolute; right: 15px; bottom: 150px; width: 200px; border: 1px solid #55c5b0; word-break: break-all; background: #348475; padding: 3px; border-radius: 5px; color: #fff;}' +
                '.btn { color: white; background: #007bff; height: 24px; padding: 10px;text-align: center; cursor: pointer; border-radius: 5px; }' +
                '</style>';


            db.collection(strings.DBTables.IMAGES).orderBy("name", "asc").get()
                .then(snapshot=>{

                    html +='<div class="ib">';
                    html += '<div id="ib-content" class="ib-content">';
                    snapshot.docs.forEach(doc=>{
                        let item = doc.data();
                        html += '<div class="list-image" onclick="SelectedItem(this,\''+item.url+'\',\''+item.name+'\')">' +
                            '<img src="' + item.url +'" alt="' + item.name +'"/>' +
                            '</div>'
                    })
                    html += '</div>';
                    html += '<div class="ib-pr">' +
                        '<div class="img"><img id="pr"/><span id="prT"></span></div>' +
                        '<div class="btn" onclick="acceptItem()">Resmi Seç ✓</div>' +
                        '</div>';
                    html += '</div>';
                    win.document.body.outerHTML= html;
                });
        }
    };
    return [
        'bold',
        'strikethrough',
        'underline',
        'italic',
        'eraser',
        'superscript',
        'subscript',
        'ul',
        'ol',
        'outdent',
        'indent',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        'image',
        fsImage,
        imageBrowser,
        'file',
        'video',
        'table',
        'link',
        'align',
        'undo',
        'redo',
        'selectall',
        'cut',
        'copy',
        'paste',
        'copyformat',
        'hr',
        'symbol',
        '|',
        'fullsize',
        'print',
        'preview',
        'find',
        'source',
        'about'
    ];
}