import firebase from 'firebase';

let FBApp = firebase.initializeApp({
    apiKey: "AIzaSyDdb-EIth1qR4a_f5B2y3nMQvzgpqrw8mw",
    authDomain: "fatihsanalcomtr.firebaseapp.com",
    databaseURL: "https://fatihsanalcomtr.firebaseio.com",
    projectId: "fatihsanalcomtr",
    storageBucket: "fatihsanalcomtr.appspot.com",
    messagingSenderId: "11876048457",
    appId: "1:11876048457:web:7144518f8ae4d7708e2998",
    measurementId: "G-8JW6EPQDG3"
});

const db = FBApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();


const APP_KEY = 'myblog:app.firebaseio.com';


export {
    db,
    auth,
    storage,
    APP_KEY
};