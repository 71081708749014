import React from 'react';
import PropTypes from 'prop-types';
import { Error } from "components";
import { formValues } from "utils";
import {Form} from "react-bootstrap";

const Input = ({ name, error, rules, ...props }) => {
    return <Form.Group controlId={name}>
        <Form.Control
            name={ name }
            ref={ rules }
            error={ error[name] }
            placeholder={ formValues.labels[name] }
            {...props}
        />
        <Error error={ error } name={ name } />
    </Form.Group>;
};
Input.propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    rules: PropTypes.func.isRequired
};

export { Input };