import React, { useEffect } from 'react';
import {
    Col,
    Container, Nav,
    Navbar,
    Row
} from "react-bootstrap";
import {Route} from "react-router-dom";
import {Icon} from "components";
import {Img} from "react-image";
import {Sidebar} from "pages/Administrator";
import 'styles/AdminRouter.scss';
import {logoutUser, strings, storageGet, APP_KEY, goAdminUrl} from "utils";

const AdminRouter = ({component: Component,  ...rest}) => {

    useEffect(()=>{
        if(!storageGet(APP_KEY))
            goAdminUrl('')
    },[])

    return <Route {...rest} render={ matchProps => (
        <>
            <Navbar bg="light" variant="light" className='shadow-sm'>
                <Container>
                    <Navbar.Brand href={strings.PATH.ADMIN + '/Dashboard'} className="mr-md-auto">
                        <Img
                            src={ process.env.PUBLIC_URL + '/image/logo_header.png' }
                            loader={<Icon item='spinner fa-spin' />}
                            unloader={<Icon item='exclamation-triangle' />}
                            alt={'M. Fatih ŞANAL'}
                            title={'M. Fatih ŞANAL'}
                            height={'25'}
                        />
                    </Navbar.Brand>

                    <Nav className="">
                        <Nav.Link onClick={()=>logoutUser()}>Çıkış</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <br/>
            <Container>
                <Row>
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <Component {...matchProps} />
                    </Col>
                </Row>
            </Container>
        </>
    )} />;
};

export { AdminRouter };