import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const Dashboard = () => {
    return <Container>
        <Row>
            <Col md={12} align="center">
                <img src={process.env.PUBLIC_URL + '/image/logo_header.png'} alt="M. Fatih ŞANAL"/>
            </Col>
        </Row>
    </Container>;
};

export {Dashboard}